<template>
  <div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow-y:scroll;">
        <bhLoading :show="loading" />
        <a-select class="change-select-color" v-if="selectedArray.length > 1" placeholder="Bulk Actions" style="width:200px; position:absolute; top:54.44px; right:20px; z-index: 900;">
            <a-select-option @click="moveButton" value="move">Move to Home Model Type</a-select-option>
            <a-select-option @click="publishModels" value="publish">Publish Selected Models</a-select-option>
            <a-select-option @click="unpublishModels" value="unpublish">Unpublish Selected Models</a-select-option>
            <a-select-option @click="deleteClick" value="delete">Delete Selected Models</a-select-option>
        </a-select>
        <a-modal :visible="moveClick" :footer="null" :centered ="true" @cancel="moveClick =! moveClick">
            <strong><h2>Move Model</h2></strong>
            <div style="display: inline-block;">
                Which Home Model Type would you want to move your Model to?
            </div>
            <div class="mt-3">
                <!-- <a-auto-complete
                    v-model="type"
                    :data-source="dataSource"
                    placeholder="Enter the type"
                    @search="onSearch"
                    @change="onChange"
                    style="width:48%"
                >

                </a-auto-complete>
                <a-auto-complete
                    v-model="size"
                    :data-source="dataSource2"
                    placeholder="Enter the size"
                    @search="onSearch2"
                    @change="onChange"
                    style="width:48%; float: right;"
                    type="number"
                >

                </a-auto-complete> -->

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Frontage (Only Digits)" required>
                            <a-input type="number" v-model="size" :placeholder="'Example value: 40'"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="Model Type" required>
                            <a-auto-complete
                                v-model="type"
                                :data-source="dataSource"
                                placeholder="Singles, towns, contemporary lane tomes. etc…"
                                @search="onSearch"
                                @change="onChange"
                                style="width:100%"
                            ></a-auto-complete>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <div class="mt-2 dF jE">
                    <a-button size="large" @click="confirmMove" type="primary">MOVE</a-button>
                    <a-button size="large" @click="moveClick =! moveClick" class="ml-3 cancel-button">CANCEL</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :visible="previewModal.visible" :footer="null" :centered="true" @cancel="closePreview" width="750px">
            <div>
                <div class="dF aC" style="justify-content: space-between">
                    <div class="dF aC">
                        <h4 @click="previewModal.showMedia = false" :style="{'color':(previewModal.showMedia ? '#9FA2A5':'#000'), 'border-bottom':(previewModal.showMedia || !previewModal.item.media || !previewModal.item.media.mediaImage || previewModal.item.media.mediaImage.length == 0 ? 'none':'var(--orange) 2px solid'), 'cursor':(!previewModal.item.media || !previewModal.item.media.mediaImage || previewModal.item.media.mediaImage.length == 0 ? '':'pointer'),}">{{previewModal.item ? previewModal.item.name:''}}</h4>
                        <h4 class="ml-5" style="cursor:pointer" @click="previewModal.showMedia = true" :style="{'color':(previewModal.showMedia ? '#000':'#9FA2A5'), 'border-bottom':(previewModal.showMedia ? 'var(--orange) 2px solid':'none')}" v-if="previewModal.item.media && previewModal.item.media.mediaImage && previewModal.item.media.mediaImage.length > 0">Media</h4>
                    </div>
                    <div v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length && !previewModal.showMedia" class="dF mr-3">
                        <div @click="changeElevation(elevationI)" style="color:#FFF; cursor:pointer" :style="chosenElevation == elevationI ? 'background-color:#F79425':'background-color:#D3D0D6'" class="p-2 mr-3" v-for="(elevation, elevationI) in findElevations(previewModal.item)" :key="elevation.id + elevationI">{{elevation.name}}</div>
                    </div>
                    <div v-else-if="previewModal.item && (!previewModal.item.units || !previewModal.item.units.length) && !previewModal.showMedia">No elevation</div>
                </div>
                <div class="mt-3" v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length && !previewModal.showMedia && images && images.length">
                    <!-- <img style="width:100%; max-height:500px" :src="previewModal.item.units[chosenElevation].image" /> -->
					<VueperSlides ref="imageSlides" :touchable="true" :infinite="false" class="floorplan-slides no-shadow" :bullets="false" :arrows="images.length > 1 ? true : false" :dragging-distance="70" fixedHeight="100%" style="width: 100%; height: 500px;">
						<VueperSlide v-for="(slide, i) in images" :key="i" :image="slide.image" style="background-size: contain; background-repeat: no-repeat;" />
					</VueperSlides>
                </div>
                <div v-else-if="previewModal.item && (!previewModal.item.units || !previewModal.item.units.length) && !previewModal.showMedia" class="mt-3 w-full" style="text-align:center; height:200px">
                    <img style="object-fit:contain; width:100%; height:100%" src="@/assets/no_elevation.png" />
                </div>
                <div class="mt-3">
                    <div class="dF aC" v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length && !previewModal.showMedia">
                        <div class="dF f1">
                            <div class="pr-3" style="border-right:1px solid #000">{{getPrice(previewModal.item.units[chosenElevation])}}</div>
                            <div class="px-3" style="border-right:1px solid #000">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].beds || 0} Beds`}}</div>
							<div class="px-3" style="border-right:1px solid #000">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].dens || 0} Den/Library`}}</div>
                            <div class="px-3" style="border-right:1px solid #000">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].baths || 0} Baths`}}</div>
                            <div class="px-3" style="border-right:1px solid #000">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].garages || 0} Car Garages`}}</div>
                            <div class="px-3" style="border-right:1px solid #000">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].stories} Stories`}}</div>
                            <div class="pl-3">{{`${previewModal.item.units[chosenElevation].packages[chosenPackage].sqft || 0} Sq.Ft`}}</div>
                        </div>
                        <div class="ml-2 dF jE" style="text-align: center;" v-if="previewModal.item.units[chosenElevation].pdf && previewModal.item.units[chosenElevation].pdf != ''">
                            <div @click="downloadFile(previewModal.item.units[chosenElevation].pdf)" class="py-2 px-3 dF aC" style="background-color:#000; color:#FFF; border-radius:4px; cursor:pointer"><i class="fe fe-download mr-3" />Floor Plan Brochure</div>
                        </div>
                    </div>
                    <div v-else-if="previewModal.item && (!previewModal.item.units || !previewModal.item.units.length) && !previewModal.showMedia">No elevation</div>
                </div>
                <hr v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length && !previewModal.showMedia">
                <div v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length && !previewModal.showMedia">
                    <h4 class="mb-3">Floor Plan</h4>
                    <div class="dF">
                        <a-select @change="changePackage" style="width:150px" v-model="chosenPackage" v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length">
                            <a-select-option v-for="(pack, packI) in previewModal.item.units[chosenElevation].packages" :key="packI">{{pack.name}}</a-select-option>
                        </a-select>
                        <div class="ml-3 dF aC" v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length">
                            <div @click="changeFloor(floorI)" style="cursor:pointer" class="ml-3" :style="chosenFloor == floorI ? '':'color:#A3A5AD'" v-for="(floor, floorI) in previewModal.item.units[chosenElevation].packages[chosenPackage].floors" :key="floorI">{{floor.name}}</div>
                        </div>
                    </div>
                    <div class="mt-3 dF aC jC" v-if="previewModal.item && previewModal.item.units && previewModal.item.units.length" style="justify-content:space-between">
                        <div :style="chosenFloor == 0 ? 'border-color:#98999A; color:#98999A':''" @click="prevFloor" class="px-2 py-3" style="border:1px solid #000; cursor:pointer"><a-icon type="left" /></div>
                        <img style="width:500px; max-height:600px; object-fit:contain" :src="previewModal.item.units[chosenElevation].packages[chosenPackage].floors[chosenFloor].image" />
                        <div :style="chosenFloor == previewModal.item.units[chosenElevation].packages[chosenPackage].floors.length-1 ? 'border-color:#98999A; color:#98999A':''" @click="nextFloor(previewModal.item.units[chosenElevation].packages[chosenPackage].floors.length)" class="px-2 py-3" style="border:1px solid #000; cursor:pointer"><a-icon type="right" /></div>
                    </div>
                </div>
                <div v-if="previewModal.item && previewModal.item.media && previewModal.item.media.mediaImage && previewModal.item.media.mediaImage.length != 0 && previewModal.showMedia">
                    <div class="w-full aC dF jC" style="justify-content:space-between">
                        <div @click="changeImage('prev')" :style="previewModal.chosenImage == 0 ? 'border-color:#98999A; color:#98999A':'cursor:pointer'"  class="px-2 py-3" style="border:1px solid #000;"><a-icon type="left" /></div>
                        <img style="width:500px; max-height:600px; object-fit:contain" :src="previewModal.item.media.mediaImage[previewModal.chosenImage].url" />
                        <div @click="changeImage('next')" :style="previewModal.chosenImage == previewModal.item.media.mediaImage.length - 1 ? 'border-color:#98999A; color:#98999A':'cursor:pointer'" class="px-2 py-3" style="border:1px solid #000;"><a-icon type="right" /></div>
                    </div>
                    <div class="dF aC mt-3">
                        <div @click="previewModal.chosenImage = imageI" style="cursor:pointer" :style="imageI == previewModal.chosenImage ? 'border: var(--orange) 2px solid':''" class="mr-3" v-for="(image,imageI) in previewModal.item.media.mediaImage" :key="imageI" :value="imageI">
                            <img style="width:120px; height:70px; object-fit:cover" :src="image.url" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>


        <template v-if="!Object.values(unitTypeList).length">
            <EmptyApp :img="require(`@/assets/empty-model.png`)" :text="'You don\'t have any Home Models. Add to get started'" />
        </template>

        <div v-else>
            <div class="dF py-3" style="justify-content:flex-end">
                <i @click="changeDisplay('block')" :style="displayMode == 'block'? 'color:var(--orange)': 'color:#D3D0D6'"  style="cursor:pointer; font-size:25px" class="mr-3 fa fa-th" />
                <i @click="changeDisplay('list')" :style="displayMode == 'list'? 'color:var(--orange)': 'color:#D3D0D6'" style="cursor: pointer; font-size:25px" class="fa fa-th-list" />
            </div>
            <div v-if="typeName==false && displayMode == 'block'">

                <h2>All Home Models</h2>
                <div v-for="(unit,unitI) in unitTypeList" :key="unitI">
                    <h5 class="mt-5">{{ unit.name }}</h5>
                    <div>
                        <draggable :list="order(unit.children)" class="model-card-cols" :delay-on-touch-only="true" :delay="100">
                        <a-col style="cursor:pointer;" @click="selectModel(model)" class="mt-3 mb-3" v-for="(model,modelI) in order(unit.children)" :key="modelI">
                            <DisplayModel :model="model" @previewModel="previewModel(model.id)" @duplicateModel="duplicateModel(model.id)" @moveModel="moveModel(model.id)" @deleteModel="deleteModel(model.id)" @editModel="editModel(model.id)" @toggleSelect="toggleSelect(model.id)" :units="model.units" :imgSrc="model.image" :name="model.name" :test="selectedArray" />
                        </a-col>
                        </draggable>
                    </div>
                </div>
            </div>
            <div v-else-if="typeName == false && displayMode == 'list'">
                <h2>All Home Models</h2>
                <div v-for="(unit,unitI) in unitTypeList" :key="unitI">
                    <h5 class="mt-5">{{ unit.name }}</h5>
                    <ModelList :unit="unit" @moveModelList="moveModelList" @previewModelList="previewModelList" @force_update="force_update" @bulkActionModels="bulkActionModels" :models="order(unit.children)" />
                </div>
            </div>
            <div v-else-if="typeName == true && displayMode == 'block'">
                <div v-for="(unit,unitI) in unitTypeList" :key="unitI">
                    <h2 v-if="checkUnit(unit)">{{ unit.name }}</h2>
                    <div >
                        <div v-if="checkUnit(unit)">
                            <draggable :list="order(unit.children)" class="model-card-cols" :delay-on-touch-only="true" :delay="100">
                            <div style="cursor:pointer;" @click="selectModel(model)" class="mt-3 mb-3" v-for="(model,modelI) in order(unit.children)" :key="modelI">
                                <DisplayModel @previewModel="previewModel(model.id)" :model="model" @duplicateModel="duplicateModel(model.id)" @moveModel="moveModel(model.id)" @deleteModel="deleteModel(model.id)" @editModel="editModel(model.id)" @toggleSelect="toggleSelect(model.id)" :units="model.units" :imgSrc="model.image" :sqft="model.sqft" :garages="model.garages" :stories="model.stories" :beds="model.beds" :baths="model.baths" :name="model.name" :test="selectedArray" />
                            </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="typeName == true && displayMode == 'list'">
                <div v-for="(unit,unitI) in unitTypeList" :key="unitI">
                    <h2 v-if="checkUnit(unit)">{{ unit.name }}</h2>
                    <div v-if="checkUnit(unit)">
                        <ModelList :unit="unit" @moveModelList="moveModelList" @previewModelList="previewModelList" @force_update="force_update" @bulkActionModels="bulkActionModels" :models="order(unit.children)" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="footer--app" style="width:100%; display: flex; ">
            <AppFooter />
        </div> -->
  </div>
</template>

<script>
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import DisplayModel from '@/components/models/DisplayModel'
import ModelList from '@/components/models/ModelList'
import deleteProp from 'bh-mod'
import AppFooter from 'bh-mod/components/common/AppFooter'
import bhLoading from 'bh-mod/components/common/Loading'
import List from 'bh-mod/components/LeftNav/List.vue'
import draggable from 'vuedraggable'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

const FileSaver = require('file-saver')
export default {
    components:{DisplayModel, EmptyApp, ModelList,AppFooter, bhLoading,draggable, VueperSlides, VueperSlide},
    data() {
        return {
            loading:false,
            chosenElevation:0,
            chosenPackage:0,
            chosenFloor:0,
            selectedArray:[],
            visible: false,
            deleteId: '',
            moveClick: false,
            type: '',
            size: '',
            dataSource:[],
            dataSource2:[],
            modelSelect:[],
            moveId:'',
            time:0,
            previewModal:{
                visible:false,
                item:{},
                showMedia:false,
                chosenImage:0
            },
            parentList:[],
			imageSlides:null,
			images:[]
        }
    },
    watch:{
        unitGroups: function (val) {
            console.log('newwwwwww',val)
        },
        '$route':{
            immediate:true,
            handler(val){
                console.log('CHANGEDDDDDDD', val)
                if (val.path.includes('showmodel') && val.params.value) {
                    this.$store.commit('changeType', val.params.value)
                    this.$store.commit('SET_CRUMBS', [val.params.value])
                } else if (val.path.includes('showmodel') && !val.params.value) {
                    let type = 'allhomemodels'
                    this.$store.commit('changeType', type)
                    this.$store.commit('SET_CRUMBS', ['All Home Models'])
                }
                this.modelSelect = []
                this.selectedArray = []
                this.$store.commit('SELECTED_ARRAY', [])
            }
        },
        selectedArray(val) {
            console.log('selectedArray', val)
            this.$store.commit('SELECTED_ARRAY', val)
        },
        modelSelect(val){
            console.log('modelSelect',val)
        }
    },
    computed:{
        displayMode(){
            return this.$store.state.models.display
        },
        unitTypeList(){
            let time = this.time
            // let unitList = []
            // console.log('unittypelist', this.unitGroups)

            // let unitTypeList = {}

            // Object.values(this.unitGroups).forEach( uG => {
            //     let label = `${uG.type}` + ` ${uG.size}`
            //     if (!unitTypeList[label]) unitTypeList[label] = {label, children:[]}
            //     unitTypeList[label].children.push(uG)

            // })
            // let list = Object.values(unitTypeList)
            // list.forEach(uG => {
            //     uG.children.forEach(model => {
            //         let beds = []
            //         let baths = []
            //         let garages = []
            //         let sqft = []
            //         model.units.forEach(ele => {
            //             beds.push(ele.packages[0].beds)
            //             baths.push(ele.packages[0].baths)
            //             garages.push(ele.packages[0].garages)
            //             sqft.push(ele.packages[0].sqft)
            //         })
            //         model.minBed = Math.min(...beds)
            //         model.maxBed = Math.max(...beds)
            //         model.minBath = Math.min(...baths)
            //         model.maxBath = Math.max(...baths)
            //         model.minGarage = Math.min(...garages)
            //         model.maxGarage = Math.max(...garages)
            //         model.minSqft = Math.min(...sqft)
            //         model.maxSqft = Math.max(...sqft)
            //     })
            // })
            // let names = Object.keys(unitTypeList)
            // names.sort( (a,b) => {
            //     if (a > b) return 1
            //     else return -1
            // })
            // let orderList = {}
            // names.forEach(x => {
            //     orderList[x] = unitTypeList[x]
            // })
            // console.log('typeeee',orderList)
            console.log('UNIT TYPE LIST FROM COMPUTED', this.$store.getters.unitTypeList)
            return this.$store.getters.unitTypeList
		},
        instance() {
            return this.$store.state.instance
        },
        appData(){
                return this.$store.state.appData
        },
        getTitle(){
            return `Create a new `
        },
        unitGroups(){
            let groups = this.$store.state.models.unitGroups
            let time = this.time
            return groups
        },
        units(){
            return this.$store.state.models.units
        },
        typeName() {
            let name = false
            if (this.$route.params.value != null) name = true
            return name
        },
    },
    methods:{
        getPrice(obj){
            let prices = []
            if (obj.packages.length){
                obj.packages.forEach(x => {
                    if (!prices.includes(x.price)){
                        prices.push(x.price);
                    }
                })
            }
            prices = prices.filter(x => x != null).sort((a,b) => a-b)
			if (prices.length == 1){
				return '$' + prices[0].toLocaleString()
			} else if (prices.length > 1){
				return '$' + prices[0].toLocaleString() + ' - ' + '$' + prices[prices.length-1].toLocaleString()
			} else {
				return '$0'
			}
        },
        findElevations(obj){
            console.log('FIND ELEVATIONS', this.units)
            return Object.values(this.units).filter(x => x.unitGroup).filter(x => x.unitGroup.id ?  x.unitGroup.id === obj.id : x.unitGroup === obj.id )
            // this.units[obj.id]
        },
        publishModels() {
            let self = this
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            this.$confirm({
                title: "Bulk Publish",
                content: h => <div>Do you want to publish these selected Home Models?</div>,
                okText: 'PUBLISH',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk() {
                    self.confirmPublish()
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        async confirmPublish() {
            console.log('publish')
            let ids = this.selectedArray
            this.loading = true
            let isDone = await this.$store.dispatch('UNPUBLISH', {type:'models',items:{ids:ids, status:'published'}})
            this.loading = false
            if (isDone){
                this.selectedArray = []
            } else {
                this.$message.error('SOMETHING HAPPENED')
            }
        },
        unpublishModels() {

            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            let self = this
            this.$confirm({
                title: "Bulk Unpublish",
                content: h => <div>Do you want to unpublish these selected Home Models?</div>,
                okText: 'UNPUBLISH',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk() {
                    self.confirmUnpublish()

                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        async confirmUnpublish() {
            console.log('unpublish')
            let ids = this.selectedArray
            this.loading = true
            let isDone = await this.$store.dispatch('UNPUBLISH', {type:'models',items:{ids:ids, status:'draft'}})
            this.loading = false
            if (isDone){
                this.selectedArray = []
            } else {
                this.$message.error('SOMETHING HAPPENED')
            }
        },
        changeImage(type) {
            if (type == 'prev') {
                if (this.previewModal.chosenImage > 0) {
                    this.previewModal.chosenImage = this.previewModal.chosenImage - 1
                }
            } else if (type == 'next') {
                if (this.previewModal.chosenImage < this.previewModal.item.media.mediaImage.length-1) {
                    this.previewModal.chosenImage = this.previewModal.chosenImage + 1
                }
            }
        },
        downloadFile(file) {
            if (!file.includes('https://')) file = 'https://' + file
            window.open(file)
        },
        order(array) {
            let order = []
            order = array.sort( (a,b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                else return 0
            })
            return order
        },
        moveModelList(id) {
            this.moveModel(id)
        },
        previewModelList(id) {
            this.previewModal.visible = true
            this.previewModal.item = this.unitGroups[id]
        },
        nextFloor(length){
            if(this.chosenFloor < length-1) {
                this.chosenFloor = this.chosenFloor + 1
            }
        },
        prevFloor(){
            if (this.chosenFloor > 0) {
                this.chosenFloor = this.chosenFloor - 1
            }
        },
        changePackage(e){
            this.chosenPackage = e
            this.chosenFloor = 0
        },
        changeFloor(index) {
            this.chosenFloor = index
        },
        changeElevation(index) {
            this.chosenElevation = index
            this.chosenPackage = 0
            this.chosenFloor = 0
			this.loadImages();
        },
		loadImages(){
			this.images = [];
			this.images.push({image:this.previewModal.item.units[this.chosenElevation].image});
			if (this.previewModal.item.units[this.chosenElevation].image2) this.images.push({image:this.previewModal.item.units[this.chosenElevation].image2});
		},
        previewModel(id) {
            console.log('idddd',id)
            this.previewModal.visible = true
            this.previewModal.item = this.unitGroups[id]
			this.loadImages();
        },
        closePreview(){
            this.previewModal.visible = false
            this.previewModal.showMedia = false
            this.previewModal.chosenImage = 0
            this.chosenFloor = 0
            this.chosenPackage = 0
            this.chosenElevation = 0
			this.images = [];
        },
        force_update(time) {
            this.time = time
        },
        bulkActionModels(item){
            if (this.selectedArray.includes(item)) {
                let index = this.selectedArray.findIndex(x => x == item)
                this.selectedArray.splice(index, 1)
            } else {
                this.selectedArray.push(item)
            }
        },
        changeDisplay(item) {
            // this.selectedArray = []
            this.parentList = this.selectedArray
            this.$store.commit('CHANGE_DISPLAY', item)
        },
        selectModel(model) {
            if (this.modelSelect.includes(model.id) || this.selectedArray.includes(model.id)){
                let index = this.modelSelect.findIndex(x => x == model.id)
                this.modelSelect.splice(index,1)
                index = this.selectedArray.findIndex(x => x == model.id)
                this.selectedArray.splice(index,1)
            } else {
                let m = model.id
                this.modelSelect.push(m)
                this.selectedArray.push(m)
            }

        },
        onSearch(searchText) {
            let temp = []
            for (var i in this.unitGroups) {
                    temp.push(this.unitGroups[i].type)
                }
                let unique = [...new Set(temp)];

                if (searchText && searchText.length >= 1) {
                    this.dataSource = unique.filter(x => {
                        x = x.toLowerCase()
                        searchText = searchText.toLowerCase()
                        return x.includes(searchText)
                    });
                }
                else {
                    return this.dataSource = []
                }
        },
        onSearch2(searchText) {
            let temp = []
            for (var i in this.unitGroups) {
                    temp.push(this.unitGroups[i].size.toString())
                }
                let unique = [...new Set(temp)];

                if (searchText && searchText.length >= 1) {
                    this.dataSource2 = unique.filter(x => {
                        return x.includes(searchText)
                    });
                }
                else {
                    return this.dataSource2 = []
                }
        },
        onChange(searchText) {
            console.log('search', searchText)
        },
        duplicateModel(id) {
            console.log('id', id)

            if (!this.unitGroups[id]) return console.error('doesnt exist')

            this.loading = true
            this.$api.put(`/unit-groups/${this.instance.id}/${id}/clone`).then(({data}) => {
                this.time = Date.now()
                this.$store.commit('DUPLICATE_MODEL', data)
                this.loading = false
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        async confirmMove() {
            if (!this.size) return this.$message.error('Please select a model size.')
            if (!this.type) return this.$message.error('Please select a model type.')
            if (this.moveId != '') {
                console.log('movemodel', this.unitGroups[this.moveId])
                let movedModel = this.unitGroups[this.moveId]
                movedModel.type = this.type.trim()
                movedModel.size = this.size
                this.$api.put(`/units/${this.instance.id}/unitgroups/${movedModel.id}`, movedModel).then(data => {
                    console.log('just moved model', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else {
                let unitArray = []
                console.log('arrayyy', this.selectedArray)
                if(this.selectedArray.length>0) {
                    this.loading = true
                    await this.$api.put(`/units/${this.instance.id}/movemodels`, {ids:this.selectedArray, type:this.type.trim(), size:this.size}).then(data => {
                        console.log('bulk move', data, this.size, this.type)
                        this.$store.commit('MOVE_MODELS',{ids:this.selectedArray, type:this.type.trim(), size:this.size})
                        this.loading = false
                    }).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                    // console.log('array', this.selectedArray, this.type, this.size)


                    // for (var i in this.unitGroups) {
                    //     for (var j=0; j<this.selectedArray.length; j++) {
                    //         if (i == this.selectedArray[j]) {
                    //             let updatedModel = this.unitGroups[i]
                    //             console.log('thisunit', updatedModel)
                    //             updatedModel.type = this.type
                    //             updatedModel.size = this.size
                    //             this.$api.put(`/units/${this.instance.id}/unitgroups/${updatedModel.id}`, updatedModel).then(data => {
                    //                 console.log('just moved', data)
                    //             })
                    //         }
                    //     }
                    // }
                }
            }

            // else {
            //     let found = Object.values(this.unitGroups).find(x => x.id == moveId)
            //     console.log('founddd', this.unitGroups[found.id])
            // }

            this.type = ''
            this.size = ''
            this.moveClick = false
            this.selectedArray = []
            this.modelSelect = []
            this.moveId = ''
        },
        moveModel(id) {
            console.log('moving model', id)
            this.moveClick = true
            this.moveId = id
        },
        moveButton() {
            if (this.$p < 20) return this.$message.error('You do not have permission to perform this action')
            this.moveClick = true
        },
        deleteClick() {
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            let self = this
            this.$confirm({
                title: "Bulk Delete",
                content: h => <div>Do you want to delete these selected Home Models? Deleting will permanently delete the all the Models</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.confirmDelete()

                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        delete() {
            this.$api.delete(`/units/${this.instance.id}/unitgroups/${this.deleteId}`).then( ({data}) => {

                this.$store.commit('SET_PROP', {where:['unitGroups',this.deleteId], del:true})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            if (this.selectedArray.includes(this.deleteId)) {
                let index = this.selectedArray.findIndex(id => id == this.deleteId)
                this.selectedArray.splice(index,1)
            }
            if (this.modelSelect.includes(this.deleteId)) {
                let index = this.modelSelect.findIndex(id => id == this.deleteId)
                this.modelSelect.splice(index,1)
            }
        },
        async confirmDelete() {
            // for (var i in this.unitTypeList) {
            //     for (var j=0; j<this.selectedArray.length; j++) {
            //         let deleteIndex = this.unitTypeList[i].children.findIndex(x => x.value == this.selectedArray[j])
            //         this.unitTypeList[i].children.splice(deleteIndex, 1)
            //     }
            // }
            // for (var j=0; j<this.selectedArray.length; j++) {
            //     this.$api.delete(`/units/${this.instance.id}/unitgroups/${this.selectedArray[j]}`)
            // }
            this.loading = true
            let done = await this.$store.dispatch('DELETE_MODELS', this.selectedArray)
            if (done) {
                this.loading = false
                this.selectedArray = []
                this.modelSelect = []
            } else {
                return this.$message.error('SOMETHING HAPPENED')
            }
        },
        deleteModel(id){
            console.log('idd', id)
            this.deleteId = id
            let self = this
			if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Home Model. Deleting will permanently delete the all the Models in this Home Model Type</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.delete()
                },
                onCancel() {
                    console.log('Cancel')
                }
            })

        },
        editModel(id){
            console.log('idddd',id)
            let crumbs = this.$store.state.models.crumbs
            let model = this.unitGroups[id]
            console.log('MODEL EDIT CLICKED', model)
            crumbs.push(model.name)
            this.$store.commit('SET_CRUMBS', crumbs)
            this.$router.push(`/modeldetails/${id}`)
        },
        toggleSelect(id){


            console.log('toggleee',id)
            // if (this.selectedArray.includes(id) ) {
            //     let index = this.selectedArray.findIndex(x => x == id)
            //     this.selectedArray.splice(index,1)
            //     index = this.modelSelect.findIndex(x => x == id)
            //     this.modelSelect.splice(index,1)
            // }
            // else {
            //     this.selectedArray.push(id)
            //     this.modelSelect.push(id)
            // }

        },
        checkUnit(data) {
            // console.log("DATAAA", data)
            this.temp = data
            // console.log('this route', this.$route.params.value)
            if (this.$route.params.value == data.label){
                // console.log("UNIT IS MATCHING")
                return true
            }
            else {
                // console.log("UNIT ISN'T MATCHING")
                return false
            }
        },
        showModels() {
            console.log('modelsss', this.unitGroups)
        },
        metaName() {
            console.log('modelllll', this.$route.params.value)
            let nan = false
            if (this.$route.params.value != null) nan = true
            console.log('nannn', nan)
        },

    },
    mounted(){
        window.FileSaver = FileSaver
    },
}
</script>

<style scoped>
.model-card-cols{
    display:grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap:20px;
}
@media screen and (min-width:900px){
    .model-card-cols{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media screen and (min-width:1150px){
    .model-card-cols{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
@media screen and (min-width:1480px){
    .model-card-cols{
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
@media screen and (min-width:1800px){
    .model-card-cols{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}
</style>
<style lang="scss">
.change-select-color .ant-select-selection__placeholder{
    color:#000 !important;
}
</style>
<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
